import { Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import GraphLineIconColored from '../../assets/icons/GraphLineIconColored.svg';
import GraphLineIcon from '../../assets/icons/GraphLineIcon.svg';
import SludgeVolumeIcon from '../../assets/icons/SludgeVolumeIcon.svg';
import SludgeVolumeIconColored from '../../assets/icons/SludgeVolumeIconColored.svg';
import SludgeIcon from '../../assets/icons/SludgeIcon.svg';
import NoSludgeIcon from '../../assets/icons/NoSludgeIcon.svg';
import OpenNewTab from '../../assets/icons/ExpandIcon.svg';
import DownloadIcon from '../../assets/icons/DownloadCSV.svg';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import minIcon30 from '../../assets/icons/30minIcon.svg';
import minIcon60 from '../../assets/icons/60minIcon.svg';
import minIcon90 from '../../assets/icons/90minIcon.svg';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ToolTip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import { useEffect, useRef, useState } from 'react';
// import convertISOToLocalTime from '../../globalComponents/dateAndTime/ConvertISOIntoLocalTime';
import useIsTablet from '../../utilities/customHooks/useIsTablet';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  fetchFlocDetectorImgDetails,
  saveFlocDetectorImgDetails,
} from '../../services/dashboardPage';
import axios from 'axios';
import LineChartFlocDetector from './FlocDetectorCharts/LineChartFlocDetector';
import StackedBarChartFlocDetector from './FlocDetectorCharts/StackBarChartFlocDetector';
import ClockIcon from '@mui/icons-material/WatchLater';
import flocDetectorDefaultSamplesData from '../../constants/flocDetectorDefaultSamplesData';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  countWords,
  downloadCSV,
  downloadCSVForBarGraph,
  formatTimeRange,
  getDayMonthYear,
  handleOnClickIssueIcon,
  handleOnClickMinutesIcon,
  processData,
} from './Helper';
import ImageDialogueBox from './FlocDetectorCommentDialogueBox/ImageDialogueBox';
import CommentDialogueBox from './FlocDetectorCommentDialogueBox/CommentDialogueBox';
import useIsDesktop from '../../utilities/customHooks/useIsDesktop';
import FactoryIcon from '@mui/icons-material/Factory';
import { SecondaryButton } from '../../styles/global/components/dpButtons';
import linkIcon from '../../constants/Images/linkIcon.svg';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip);
ChartJS.unregister(zoomPlugin);

// ChartJS.unregister(zoomPlugin);
const styles: any = {
  hiddenComponent: {
    visibility: 'hidden',
  },
  visibleComponent: {
    visibility: 'visible',
  },
  displayNoneComponent: {
    display: 'none',
  },
  widgetHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    padding: '15px',
    color: '#193458',
    fontFace: 'Roboto',
    fontWeight: '500',
    overflow: 'hidden',
    fontSize: 'clamp(16px,1.1vw,25px)',
  },
};

const FlocDetectorVisualizationChart = (props: any) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const {
    widgetData,
    showLinkIcon,
    linkOnWidget,
    handleEditClick,
    showClock,
    customTimeRangeSettings,
    timeFrame,
    handleClockClick,
    flocData,
    widgetTimeFrame,
    setSelectedChart,
    selectChart,
  } = props;
  const userId = localStorage.getItem('userId');
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  const [selectedTypeGraph, setSelectedTypeGraph] = useState<any>('Sludge Settling Pattern');

  const barGraphData = widgetData?.metricsData?.barGraph;

  const [addComment, setAddComment] = useState({
    title: '',
    comment: '',
  });

  const [allComments, setAllComments] = useState<any>([]);
  const [imageDetails, setImageDetails] = useState<any>(null);
  const [imgId, setImgId] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentShiftIndex, setCurrentShiftIndex] = useState(0);
  const [currentSampleIndex, setCurrentSampleIndex] = useState(0);
  let currentStartDate: React.MutableRefObject<string> = useRef(timeFrame.startDate.toISOString());
  let currentEndDate: React.MutableRefObject<string> = useRef(timeFrame.endDate.toISOString());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openComment = Boolean(anchorEl);

  const [anchorElComment, setAnchorElComment] = useState<null | HTMLElement>(null);
  const openCommentDialogue = Boolean(anchorElComment);
  const [commentDialogueHeaderText, setCommentDialogueHeaderText] = useState(null);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorComment, setErrorComment] = useState(false);

  const imageCache: { [url: string]: HTMLImageElement } = {};
  const [issueIcon, setIssueIcon] = useState<{ [key: string]: boolean }>({});
  const [hiddenLabels, setHiddenLabels] = useState<{ [key: string]: boolean }>({});
  const [currentDateIndex, setCurrentDateIndex] = useState(0); // Tracks the current date

  const currentData = flocData ? flocData[currentDateIndex] : {};
  const sampleKeys = Object.keys(currentData?.samples || {});
  const currentSampleKey = sampleKeys[currentSampleIndex];
  const currentSample = currentData?.samples ? currentData?.samples[currentSampleKey] : {};

  const formatTime = (time: string | Date): string => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero to minutes if necessary

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const handleNextSample = () => {
    if (currentSampleIndex < sampleKeys.length - 1) {
      setCurrentSampleIndex(prev => prev + 1);
    } else if (currentDateIndex < flocData?.length - 1) {
      setCurrentDateIndex(prev => prev + 1);
      setCurrentSampleIndex(0);
    }
  };

  const handlePreviousSample = () => {
    if (currentSampleIndex > 0) {
      setCurrentSampleIndex(prev => prev - 1);
    } else if (currentDateIndex > 0) {
      setCurrentDateIndex(prev => prev - 1);
      setCurrentSampleIndex(Object.keys(flocData[currentDateIndex - 1]?.samples || {}).length - 1);
    }
  };

  const handleSelectedGraphType = (value: string) => {
    setSelectedTypeGraph(value);
  };

  // =================MAKE NEXT SAMPLE AND PREVIOUS SAMPLE SHOW IN HERE ==================
  // ====================================================================================
  // ====================================================================================
  // ====================================================================================
  // ====================================================================================
  // =================MAKE NEXT SAMPLE AND PREVIOUS SAMPLE SHOW IN HERE ==================

  // PUT THIS AND fetchFlocImgData IN THE IMAGE DIALOGBOX AND GET THE setImgId setOpen ONLY
  const handleClickOpen = (imageId: any) => {
    setImgId(imageId);
    setOpen(true);
    fetchFlocImgData(imageId);
  };

  // PUT  fetchFlocImgData IN THE IMAGE DIALOGBOX AND GET THE setImageDetails setAllComments ONLY
  const fetchFlocImgData = async (imageId: string) => {
    try {
      let data = await fetchFlocDetectorImgDetails({
        imageId,
      });
      setImageDetails(data.imageDetails);
      setAllComments(data.comments);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err);
      }
    }
  };

  // PUT THIS SPECIALLY TO THE IMAGE COMPONENT AND REMOVE CONSOLE AND PUT TOAST
  const saveFlocImgData = async (
    eventId: string,
    userId: any,
    imageId: any,
    title: string,
    comment: string
  ) => {
    try {
      let data = await saveFlocDetectorImgDetails({
        eventId,
        userId,
        imageId,
        title,
        comment,
      });
      if (data) {
        console.log('Commented!');
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err);
      }
    }
  };

  // PUT THIS IN THE IMAGE DIALOGBOX AND GET THE setAnchorEl ONLY
  const handleClose = () => {
    setOpen(false);
  };

  // PUT THIS IN THE IMAGE DIALOGBOX AND GET THE setAnchorEl ONLY
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // TRY MOVING THIS TOO TO SOME OTHER COMMENT COMPONENT IT IS BEING PASSED
  const handleCloseComment = (event: React.MouseEvent<HTMLElement>, value: any) => {
    if (value === 'backdropClick') {
      setAnchorEl(null);
      return;
    }
    setCommentDialogueHeaderText(value);
    setAnchorEl(null);
    setAnchorElComment(event.currentTarget);
    setOpen(false);
  };

  // TRY MOVING THIS TOO TO SOME OTHER COMMENT COMPONENT IT IS BEING PASSED
  const handleCloseCommentDialogue = () => {
    setAnchorElComment(null);
    setOpen(true);
    setAddComment({ title: '', comment: '' });
    setErrorTitle(false);
    setErrorComment(false);
  };

  // TRY MOVING THIS TOO TO SOME OTHER COMMENT COMPONENT IT IS BEING PASSED
  const handleCloseCommentDialogueOutside = () => {
    setAnchorElComment(null);
    setOpen(false);
    setAddComment({ title: '', comment: '' });
    setErrorTitle(false);
    setErrorComment(false);
  };

  // TRY MOVING THIS TOO TO SOME OTHER COMMENT COMPONENT IT IS BEING PASSED
  const handleOnChangeComment = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setAddComment({ ...addComment, [name]: value });
  };

  // TRY MOVING THIS TOO TO SOME OTHER COMMENT COMPONENT IT IS BEING PASSED
  const handleAddComment = () => {
    const { title, comment } = addComment;

    // Validation
    let valid = true;
    if (title.length < 4 || title.length > 20) {
      setErrorTitle(true);
      valid = false;
    } else {
      setErrorTitle(false);
    }

    if (countWords(comment) < 5) {
      setErrorComment(true);
      valid = false;
    } else {
      setErrorComment(false);
    }

    if (!valid) {
      return;
    }

    setAllComments([...allComments, addComment]);
    setAddComment({ title: '', comment: '' });
    saveFlocImgData(
      flocData[currentShiftIndex].samples[currentSampleIndex]?.eventId,
      userId,
      imgId,
      title,
      comment
    );
    handleCloseCommentDialogue();
  };

  const [visibleIcons, setVisibleIcons] = useState<any>({
    issueIcon: false,
    falseDetection: false,
    noSludge: false,
  });

  // THIS USEEFFECT IS FOR THE CLOCK THING AND IS FINE I GUESS
  useEffect(() => {
    currentStartDate.current =
      showClock === 'on'
        ? customTimeRangeSettings?.startTime instanceof Date
          ? customTimeRangeSettings.startTime.toISOString()
          : new Date(customTimeRangeSettings?.startTime).toISOString()
        : new Date(timeFrame?.startDate).toISOString();

    currentEndDate.current =
      showClock === 'on'
        ? customTimeRangeSettings?.endTime instanceof Date
          ? customTimeRangeSettings.endTime.toISOString()
          : new Date(customTimeRangeSettings?.endTime).toISOString()
        : new Date(timeFrame?.endDate).toISOString();
  }, [
    timeFrame?.startDate,
    timeFrame?.endDate,
    customTimeRangeSettings?.startTime,
    customTimeRangeSettings?.endTime,
  ]);

  const [openExpandedView, setOpenExpandedView] = useState(false);
  const [resetZoom, setResetZoom] = useState(false);

  const handleClickOpenExpandedView = () => {
    setOpenExpandedView(true);
    ChartJS.register(zoomPlugin);
  };

  const handleCloseExpandedView = () => {
    setOpenExpandedView(false);
    ChartJS.unregister(zoomPlugin);
  };

  useEffect(() => {
    if (setSelectedChart && selectChart) {
      setSelectedChart('flocDetector');
    }
  }, [setSelectedChart, selectChart]);

  return (
    <>
      <Grid
        container
        md={12}
        lg={12}
        border="1px solid #0000003B"
        borderRadius="16px"
        pl="12px"
        pt="10px"
        pr="20px"
        pb="0px"
        display="flex"
        flexDirection="column"
        width="94.8vw"
        sx={{ backgroundColor: '#ffffff' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!isMobile ? (
          <>
            <Grid
              container
              alignItems="flex-start"
              gap={3}
              justifyContent="space-between"
              flexWrap="nowrap"
            >
              <Grid item display="flex" gap={3} alignItems="flex-start">
                {/* Name and icon for description */}
                <Grid item display="flex" flexDirection="column" gap={1}>
                  {/* Name and icon for description */}
                  <Grid item display="flex" alignItems="center">
                    <Typography
                      variant="h4"
                      color="#1A3356"
                      fontWeight="600"
                      fontSize="18px"
                      sx={{ display: 'inline-flex', whiteSpace: 'nowrap' }} // Ensure text doesn't wrap
                    >
                      {widgetData?.widgetName || widgetData?.widgetNickName || 'Floc Detector'}
                    </Typography>
                    <ToolTip sx={styles.showIconStyle} title={widgetData?.widgetDescription}>
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                          color: '#80858CD9',
                          marginLeft: '10px !important',
                          ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                        }}
                      />
                    </ToolTip>
                  </Grid>

                  {/* Clock section */}
                  {isDesktop && (
                    <Grid item display="flex" alignItems="center" gap={1}>
                      {showClock === 'on' ? (
                        <Stack direction="row" spacing={1}>
                          <ClockIcon
                            sx={{
                              width: '18px',
                              height: '18px',
                              cursor: 'pointer',
                              color: '#5cb85c',
                            }}
                            onClick={() => handleClockClick('disabled')}
                          />
                          <span
                            style={{
                              fontSize: '13px',
                              color: '#7D8188',
                              display: 'inline-flex',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {formatTimeRange(widgetTimeFrame)}
                          </span>
                        </Stack>
                      ) : (
                        <Stack direction="row" spacing={1}>
                          <ClockIcon
                            sx={{
                              width: '18px',
                              height: '18px',
                              cursor: 'pointer',
                            }}
                            color="disabled"
                            onClick={() => handleClockClick('on')}
                          />
                          <span
                            style={{
                              fontSize: '13px',
                              color: '#7D8188',
                              display: 'inline-flex',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {formatTimeRange(widgetTimeFrame)}
                          </span>
                        </Stack>
                      )}
                    </Grid>
                  )}
                </Grid>

                {/* Graph type buttons */}
                <Grid item display="flex" alignItems="center" gap={3} mt={-1}>
                  <ToolTip
                    title="Sludge Value"
                    slotProps={{
                      popper: {
                        sx: {
                          [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                            { marginTop: '0px' },
                        },
                      },
                    }}
                  >
                    <Button
                      onClick={() => handleSelectedGraphType('Sludge Value')}
                      sx={{
                        padding: 0,
                        minWidth: 'auto',
                        backgroundColor: 'transparent',
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                      // disabled={checkbarGraphData()}
                    >
                      <img
                        src={
                          selectedTypeGraph === 'Sludge Value'
                            ? SludgeVolumeIconColored
                            : SludgeVolumeIcon
                        }
                        style={{ cursor: 'pointer' }}
                      />
                    </Button>
                  </ToolTip>

                  <ToolTip
                    title="Sludge Settling Pattern"
                    slotProps={{
                      popper: {
                        sx: {
                          [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                            { marginTop: '0px' },
                        },
                      },
                    }}
                  >
                    <Button
                      onClick={() => handleSelectedGraphType('Sludge Settling Pattern')}
                      sx={{
                        padding: 0,
                        minWidth: 'auto',
                        backgroundColor: 'transparent',
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                    >
                      <img
                        src={
                          selectedTypeGraph === 'Sludge Settling Pattern'
                            ? GraphLineIconColored
                            : GraphLineIcon
                        }
                        style={{ cursor: 'pointer' }}
                      />
                    </Button>
                  </ToolTip>
                </Grid>
              </Grid>

              {/* Date and sample navigation */}
              {selectedTypeGraph === 'Sludge Settling Pattern' && flocData?.length > 0 && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginRight: '250px' }}
                >
                  <Grid item>
                    <IconButton
                      onClick={handlePreviousSample}
                      disabled={currentDateIndex === 0 && currentSampleIndex === 0}
                      sx={{
                        fontSize: isMobile ? '0.7rem' : '0.8rem', // Adjust button font size for mobile
                        padding: '4px',
                      }}
                    >
                      <KeyboardArrowLeftIcon fontSize="small" />
                    </IconButton>
                  </Grid>

                  <Grid item textAlign="center">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: isMobile ? '0.55rem' : '0.65rem', // Adjust font size for mobile
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#7D8188', // Grey color for the text
                      }}
                    >
                      <AccessTimeIcon
                        fontSize="small"
                        sx={{ marginRight: '4px', color: '#80858CD9' }}
                      />
                      {new Date(currentData?.date)
                        .toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        })
                        .replace(',', '')}
                    </Typography>

                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: isMobile ? '0.5rem' : '0.6rem', // Adjust font size for mobile
                        color: '#7D8188', // Grey color for the sample name and bracket data
                      }}
                    >
                      {currentSampleKey} ({formatTime(currentSample?.startTime)} -{' '}
                      {formatTime(currentSample?.endTime)})
                    </Typography>

                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: isMobile ? '0.4rem' : '0.5rem', // Adjust font size for mobile
                        color: '#7D8188', // Grey color for the 1/1 or 1/2
                      }}
                    >
                      {`${currentSampleIndex + 1} / ${sampleKeys.length}`}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <IconButton
                      onClick={handleNextSample}
                      disabled={
                        currentDateIndex === flocData?.length - 1 &&
                        currentSampleIndex === sampleKeys.length - 1
                      }
                      sx={{
                        fontSize: isMobile ? '0.7rem' : '0.8rem', // Adjust button font size for mobile
                        padding: '4px',
                      }}
                    >
                      <KeyboardArrowRightIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              {/* THIS IS WHERE WE WILL ADD OUR SAMPLE LOGIC AND REMOVE THE SHIFT LOGIC  */}

              {selectedTypeGraph === 'Sludge Value' && (
                <Grid item display="flex" justifyContent="flex-end" alignItems="center" gap={3}>
                  <Grid item display="flex" gap={3}>
                    <Grid
                      item
                      display="flex"
                      onClick={() => {
                        handleOnClickIssueIcon(
                          'falseDetectionIcon',
                          selectedTypeGraph,
                          setIssueIcon,
                          setVisibleIcons
                        );
                      }}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
                      <Typography
                        color="#5C6066"
                        fontSize="13px"
                        whiteSpace="nowrap"
                        style={
                          issueIcon?.falseDetectionIcon ? { textDecoration: 'line-through' } : {}
                        }
                      >
                        False Detection
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickIssueIcon(
                          'floatingSludgeIcon',
                          selectedTypeGraph,
                          setIssueIcon,
                          setVisibleIcons
                        );
                      }}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      <img src={SludgeIcon} />
                      <Typography
                        color="#5C6066"
                        fontSize="13px"
                        whiteSpace="nowrap"
                        style={
                          issueIcon?.floatingSludgeIcon ? { textDecoration: 'line-through' } : {}
                        }
                      >
                        Floating Sludge
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickIssueIcon(
                          'noSludgeIcon',
                          selectedTypeGraph,
                          setIssueIcon,
                          setVisibleIcons
                        );
                      }}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      <img src={NoSludgeIcon} />
                      <Typography
                        color="#5C6066"
                        fontSize="13px"
                        whiteSpace="nowrap"
                        style={issueIcon?.noSludgeIcon ? { textDecoration: 'line-through' } : {}}
                      >
                        No Sludge
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item display="flex" alignItems="center" gap={3}>
                    <ToolTip title="Download CSV">
                      <Button
                        onClick={() => {
                          if (selectedTypeGraph === 'Sludge Settling Pattern') {
                            downloadCSV(
                              flocData[currentDateIndex]?.date, // Use the current date instead of shiftName
                              getDayMonthYear(flocData[currentDateIndex]?.date), // Format the date as Day/Month/Year
                              processData(flocData[currentDateIndex]?.samples[currentSampleKey]) // Process data for the selected sample
                            );
                          } else if (selectedTypeGraph === 'Sludge Value') {
                            downloadCSVForBarGraph(flocDetectorDefaultSamplesData);
                          }
                        }}
                        sx={{
                          padding: 0,
                          minWidth: 'auto',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <img
                          src={DownloadIcon}
                          style={{
                            cursor: 'pointer',
                            ...(isHovered ? styles?.visibleComponent : styles.hiddenComponent),
                          }}
                        />
                      </Button>
                    </ToolTip>

                    <ToolTip title="Expanded View">
                      <Button
                        onClick={handleClickOpenExpandedView}
                        sx={{
                          padding: 0,
                          minWidth: 'auto',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <img
                          src={OpenNewTab}
                          style={{
                            cursor: 'pointer',
                            ...(isHovered ? styles?.visibleComponent : styles?.hiddenComponent),
                          }}
                        />
                      </Button>
                    </ToolTip>

                    {/* in case of  BAR GRAPH*/}

                    <ToolTip title="Edit Widget">
                      <ModeEditOutlineIcon
                        fontSize="small"
                        sx={{
                          width: '16px',
                          height: '16px',
                          cursor: 'pointer',
                          color: '#80858CD9',
                          ...(isHovered ? styles?.visibleComponent : styles?.hiddenComponent),
                        }}
                        onClick={() => {
                          handleEditClick('flocDetector');
                        }}
                      />
                    </ToolTip>
                  </Grid>
                </Grid>
              )}
              {selectedTypeGraph === 'Sludge Settling Pattern' && (
                <Grid item display="flex" alignItems="center" gap={3} mt={isTablet ? '5px' : '0px'}>
                  <ToolTip title="Download CSV">
                    <Button
                      onClick={() => {
                        if (flocData?.length > 0) {
                          // Only trigger download if flocData is not empty
                          if (selectedTypeGraph === 'Sludge Settling Pattern') {
                            downloadCSV(
                              flocData[currentDateIndex]?.date, // Use the current date instead of shiftName
                              getDayMonthYear(flocData[currentDateIndex]?.date), // Format the date as Day/Month/Year
                              processData(flocData[currentDateIndex]?.samples[currentSampleKey]) // Process data for the selected sample
                            );
                          } else if (selectedTypeGraph === 'Sludge Value') {
                            downloadCSVForBarGraph(flocDetectorDefaultSamplesData);
                          }
                        }
                      }}
                      disabled={flocData?.length === 0}
                      sx={{
                        padding: 0,
                        minWidth: 'auto',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      <img
                        src={DownloadIcon}
                        style={{
                          cursor: flocData?.length === 0 ? 'not-allowed' : 'pointer',
                          ...(isHovered && flocData?.length > 0
                            ? styles?.visibleComponent
                            : styles.hiddenComponent),
                          opacity: flocData?.length === 0 ? 0.5 : 1, // Reduce opacity to indicate disabled state
                        }}
                      />
                    </Button>
                  </ToolTip>

                  <ToolTip title="Expanded View">
                    <Button
                      onClick={() => {
                        if (flocData?.length > 0) {
                          handleClickOpenExpandedView();
                        }
                      }}
                      disabled={flocData?.length === 0}
                      sx={{
                        padding: 0,
                        minWidth: 'auto',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      <img
                        src={OpenNewTab}
                        style={{
                          cursor: flocData?.length === 0 ? 'not-allowed' : 'pointer',
                          ...(isHovered && flocData?.length > 0
                            ? styles?.visibleComponent
                            : styles.hiddenComponent),
                          opacity: flocData?.length === 0 ? 0.5 : 1, // Reduce opacity to indicate disabled state
                        }}
                      />
                    </Button>
                  </ToolTip>

                  {/* in case of  linechart*/}
                  <ToolTip title="Edit Widget">
                    <ModeEditOutlineIcon
                      fontSize="small"
                      sx={{
                        width: '16px',
                        height: '16px',
                        cursor: 'pointer',
                        color: '#80858CD9',
                        ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                      }}
                      onClick={() => {
                        handleEditClick('flocDetector');
                      }}
                    />
                  </ToolTip>
                </Grid>
              )}
            </Grid>

            {/* THIS IS THE BLOCK TO KEEP THE FALSE FLOATING NOSLUDGE ICON AND TEXT IN LINEgRAPH  START*/}
            {selectedTypeGraph === 'Sludge Settling Pattern' && (
              <Grid
                item
                display="flex"
                justifyContent={isTablet ? 'space-between' : 'flex-end'}
                alignItems="center"
                gap={3}
                mt={isDesktop ? -3 : 0}
              >
                {isTablet && (
                  <Grid item display="flex" gap={1}>
                    {showClock == 'on' && (
                      <Stack direction="row" spacing={1}>
                        <ClockIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                            color: '#5cb85c	',
                          }}
                          onClick={() => handleClockClick('disabled')}
                        />
                        <span style={{ fontSize: '13px', color: '#7D8188' }}>
                          {formatTimeRange(widgetTimeFrame)}
                        </span>
                      </Stack>
                    )}
                    {showClock === 'disabled' && (
                      <Stack direction="row" spacing={1}>
                        <ClockIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                          }}
                          color="disabled"
                          onClick={() => handleClockClick('on')}
                        />
                        <span style={{ fontSize: '13px', color: '#7D8188' }}>
                          {formatTimeRange(widgetTimeFrame)}
                        </span>
                      </Stack>
                    )}
                  </Grid>
                )}
                <Grid display="flex" item gap={3} mt={1}>
                  <Grid
                    item
                    display="flex"
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'falseDetection',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.falseDetection ? { textDecoration: 'line-through' } : {}}
                    >
                      False Detection
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'issueIcon',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={SludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.issueIcon ? { textDecoration: 'line-through' } : {}}
                    >
                      Floating Sludge
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'noSludge',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={NoSludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.noSludge ? { textDecoration: 'line-through' } : {}}
                    >
                      No Sludge
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* THIS IS THE BLOCK TO KEEP THE FALSE FLOATING NOSLUDGE ICON AND TEXT IN LINEgRAPH END*/}

            {/* THIS IS TO KEEPP THE 30 , 60 , 90 MIN ICON AND TEXT IN BARGRAPH START */}
            {selectedTypeGraph === 'Sludge Value' && (
              <Grid
                item
                display="flex"
                justifyContent={isTablet ? 'space-between' : 'flex-end'}
                alignItems="center"
                gap={3}
                mt={isDesktop ? -3 : 0}
              >
                {isTablet && (
                  <Grid item display="flex" gap={1}>
                    {showClock == 'on' && (
                      <Stack direction="row" spacing={1}>
                        <ClockIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                            color: '#5cb85c	',
                          }}
                          onClick={() => handleClockClick('disabled')}
                        />
                        <span style={{ fontSize: '13px', color: '#7D8188' }}>
                          {formatTimeRange(widgetTimeFrame)}
                        </span>
                      </Stack>
                    )}
                    {showClock === 'disabled' && (
                      <Stack direction="row" spacing={1}>
                        <ClockIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                          }}
                          color="disabled"
                          onClick={() => handleClockClick('on')}
                        />
                        <span style={{ fontSize: '13px', color: '#7D8188' }}>
                          {formatTimeRange(widgetTimeFrame)}
                        </span>
                      </Stack>
                    )}
                  </Grid>
                )}
                <Grid item display="flex" gap={3} sx={{ mt: '10px' }}>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickMinutesIcon('30 minutes', setHiddenLabels);
                    }}
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                  >
                    <img
                      src={minIcon30}
                      style={{
                        height: '14px',
                        width: '14px',
                      }}
                    />
                    <Typography
                      color="#5C6066"
                      fontSize="12px"
                      whiteSpace="nowrap"
                      style={
                        hiddenLabels['30 minutes']
                          ? {
                              textDecoration: 'line-through',
                            }
                          : {}
                      }
                    >
                      30 Min
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickMinutesIcon('60 minutes', setHiddenLabels);
                    }}
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                  >
                    <img src={minIcon60} style={{ height: '14px', width: '14px' }} />
                    <Typography
                      color="#5C6066"
                      fontSize="12px"
                      whiteSpace="nowrap"
                      style={hiddenLabels['60 minutes'] ? { textDecoration: 'line-through' } : {}}
                    >
                      60 Min
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickMinutesIcon('90 minutes', setHiddenLabels);
                    }}
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                  >
                    <img src={minIcon90} style={{ height: '14px', width: '14px' }} />
                    <Typography
                      color="#5C6066"
                      fontSize="12px"
                      whiteSpace="nowrap"
                      style={hiddenLabels['90 minutes'] ? { textDecoration: 'line-through' } : {}}
                    >
                      90 Min
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* THIS IS TO KEEPP THE 30 , 60 , 90 MIN ICON AND TEXT IN BARGRAPH END */}
          </>
        ) : (
          <>
            <Grid container alignItems="flex-start" direction="column">
              <Grid item display="flex" gap={3} justifyContent="space-between" width="100%">
                <Grid item alignItems="center" gap={2} display="flex">
                  <Typography variant="h4" color="#1A3356" fontWeight="600" fontSize="18px">
                    {widgetData?.widgetNickName}
                  </Typography>
                  <ToolTip sx={styles.showIconStyle} title={widgetData?.widgetDescription}>
                    <InfoOutlinedIcon
                      fontSize="small"
                      sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        color: '#80858CD9',
                        alignSelf: 'center',
                        marginLeft: '10px !important',
                        ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                      }}
                    />
                  </ToolTip>
                </Grid>
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  gap={3}
                  sx={isHovered ? styles.visibleComponent : styles.hiddenComponent}
                >
                  <ToolTip title="Download CSV">
                    <img
                      src={DownloadIcon}
                      onClick={() => {
                        if (selectedTypeGraph === 'Sludge Settling Pattern') {
                          downloadCSV(
                            flocData[currentDateIndex]?.date, // Use the current date instead of shiftName
                            getDayMonthYear(flocData[currentDateIndex]?.date), // Format the date as Day/Month/Year
                            processData(flocData[currentDateIndex]?.samples[currentSampleKey]) // Process data for the selected sample
                          );
                        } else if (selectedTypeGraph === 'Sludge Value') {
                          downloadCSVForBarGraph(flocDetectorDefaultSamplesData);
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </ToolTip>
                  <ToolTip title="Expanded View">
                    <img
                      src={OpenNewTab}
                      style={{ cursor: 'pointer' }}
                      onClick={handleClickOpenExpandedView}
                    />
                  </ToolTip>
                  <ToolTip title="Edit Widget">
                    <ModeEditOutlineIcon
                      fontSize="small"
                      sx={{
                        width: '16px',
                        height: '16px',
                        cursor: 'pointer',
                        color: '#80858CD9',
                      }}
                      onClick={() => {
                        handleEditClick('flocDetector');
                      }}
                    />
                  </ToolTip>
                </Grid>
              </Grid>
              <Grid item display="flex" gap={1} mb={1} mt={1}>
                <Grid item display="flex" gap={1}>
                  {showClock == 'on' && (
                    <Stack direction="row" spacing={1} mb={1}>
                      <ClockIcon
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                          color: '#5cb85c	',
                        }}
                        onClick={() => handleClockClick('disabled')}
                      />
                      <span style={{ fontSize: '13px', color: '#7D8188' }}>
                        {formatTimeRange(widgetTimeFrame)}
                      </span>
                    </Stack>
                  )}
                  {showClock === 'disabled' && (
                    <Stack direction="row" spacing={1} mb={1}>
                      <ClockIcon
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                        }}
                        color="disabled"
                        onClick={() => handleClockClick('on')}
                      />
                      <span style={{ fontSize: '13px', color: '#7D8188' }}>
                        {formatTimeRange(widgetTimeFrame)}
                      </span>
                    </Stack>
                  )}
                </Grid>
                {/* Date and sample navigation */}
                {selectedTypeGraph === 'Sludge Settling Pattern' && flocData?.length > 0 && (
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <IconButton
                        onClick={handlePreviousSample}
                        disabled={currentDateIndex === 0 && currentSampleIndex === 0}
                        sx={{
                          fontSize: isMobile ? '0.7rem' : '0.8rem', // Adjust button font size for mobile
                          padding: '4px',
                        }}
                      >
                        <KeyboardArrowLeftIcon fontSize="small" />
                      </IconButton>
                    </Grid>

                    <Grid item textAlign="center">
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: isMobile ? '0.55rem' : '0.65rem', // Adjust font size for mobile
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#7D8188', // Grey color for the text
                        }}
                      >
                        <AccessTimeIcon
                          fontSize="small"
                          sx={{ marginRight: '4px', color: '#80858CD9' }}
                        />
                        {new Date(currentData?.date)
                          .toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })
                          .replace(',', '')}
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: isMobile ? '0.5rem' : '0.6rem', // Adjust font size for mobile
                          color: '#7D8188', // Grey color for the sample name and bracket data
                        }}
                      >
                        {currentSampleKey} ({formatTime(currentSample?.startTime)} -{' '}
                        {formatTime(currentSample?.endTime)})
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: isMobile ? '0.4rem' : '0.5rem', // Adjust font size for mobile
                          color: '#7D8188', // Grey color for the 1/1 or 1/2
                        }}
                      >
                        {`${currentSampleIndex + 1} / ${sampleKeys.length}`}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <IconButton
                        onClick={handleNextSample}
                        disabled={
                          currentDateIndex === flocData?.length - 1 &&
                          currentSampleIndex === sampleKeys.length - 1
                        }
                        sx={{
                          fontSize: isMobile ? '0.7rem' : '0.8rem', // Adjust button font size for mobile
                          padding: '4px',
                        }}
                      >
                        <KeyboardArrowRightIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item display="flex" alignItems="center" gap={3}>
                <ToolTip
                  title="Sludge Value"
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                          {
                            marginTop: '0px',
                          },
                      },
                    },
                  }}
                >
                  <img
                    src={
                      selectedTypeGraph === 'Sludge Value'
                        ? SludgeVolumeIconColored
                        : SludgeVolumeIcon
                    }
                    onClick={() => handleSelectedGraphType('Sludge Value')}
                    style={{ cursor: 'pointer' }}
                  />
                </ToolTip>
                <ToolTip
                  title="Sludge Settling Pattern"
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                          {
                            marginTop: '0px',
                          },
                      },
                    },
                  }}
                >
                  <img
                    src={
                      selectedTypeGraph === 'Sludge Settling Pattern'
                        ? GraphLineIconColored
                        : GraphLineIcon
                    }
                    onClick={() => handleSelectedGraphType('Sludge Settling Pattern')}
                    style={{ cursor: 'pointer' }}
                  />
                </ToolTip>
                {/* <img src={SludgeVolumeGroupIcon} /> */}
              </Grid>
              {selectedTypeGraph === 'Sludge Value' && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={3}
                  mt={1}
                >
                  <Grid item display="flex" gap={3}>
                    <Grid
                      item
                      display="flex"
                      onClick={() => {
                        handleOnClickIssueIcon(
                          'falseDetectionIcon',
                          selectedTypeGraph,
                          setIssueIcon,
                          setVisibleIcons
                        );
                      }}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={
                          issueIcon.falseDetectionIcon ? { textDecoration: 'line-through' } : {}
                        }
                      >
                        False Detection
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickIssueIcon(
                          'floatingSludgeIcon',
                          selectedTypeGraph,
                          setIssueIcon,
                          setVisibleIcons
                        );
                      }}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      <img src={SludgeIcon} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={
                          issueIcon.floatingSludgeIcon ? { textDecoration: 'line-through' } : {}
                        }
                      >
                        Floating Sludge
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickIssueIcon(
                          'noSludgeIcon',
                          selectedTypeGraph,
                          setIssueIcon,
                          setVisibleIcons
                        );
                      }}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      <img src={NoSludgeIcon} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={issueIcon.noSludgeIcon ? { textDecoration: 'line-through' } : {}}
                      >
                        No Sludge
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {selectedTypeGraph === 'Sludge Value' && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  mt={1}
                  mb={2}
                >
                  <Grid item display="flex" gap={3}>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickMinutesIcon('30 minutes', setHiddenLabels);
                      }}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                    >
                      <img src={minIcon30} style={{ width: '12px', height: '12px' }} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={hiddenLabels['30 minutes'] ? { textDecoration: 'line-through' } : {}}
                      >
                        30 Min
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickMinutesIcon('60 minutes', setHiddenLabels);
                      }}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                    >
                      <img src={minIcon60} style={{ width: '12px', height: '12px' }} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={hiddenLabels['60 minutes'] ? { textDecoration: 'line-through' } : {}}
                      >
                        60 Min
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      gap={1}
                      onClick={() => {
                        handleOnClickMinutesIcon('90 minutes', setHiddenLabels);
                      }}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                    >
                      <img src={minIcon90} style={{ width: '12px', height: '12px' }} />
                      <Typography
                        color="#5C6066"
                        fontSize="11px"
                        whiteSpace="nowrap"
                        style={hiddenLabels['90 minutes'] ? { textDecoration: 'line-through' } : {}}
                      >
                        90 Min
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {selectedTypeGraph === 'Sludge Settling Pattern' && (
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={3}
                  mt={3}
                >
                  <Grid
                    item
                    display="flex"
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'falseDetection',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.falseDetection ? { textDecoration: 'line-through' } : {}}
                    >
                      False Detection
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'issueIcon',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={SludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.issueIcon ? { textDecoration: 'line-through' } : {}}
                    >
                      Floating Sludge
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    gap={1}
                    onClick={() => {
                      handleOnClickIssueIcon(
                        'noSludge',
                        selectedTypeGraph,
                        setIssueIcon,
                        setVisibleIcons
                      );
                    }}
                    sx={{ cursor: 'pointer' }}
                    alignItems="center"
                  >
                    <img src={NoSludgeIcon} />
                    <Typography
                      color="#5C6066"
                      fontSize="13px"
                      whiteSpace="nowrap"
                      style={visibleIcons.noSludge ? { textDecoration: 'line-through' } : {}}
                    >
                      No Sludge
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}

        {/*  THIS BLOCK IS REAL IMPORTANT TO KEEP THINGS IN SHAPE   */}
        <Grid container mt="0.5rem" sx={{ textAlign: 'center', border: '10px' }}>
          {(flocData == 'undefined' || flocData == null || flocData?.length == 0) && (
            <Box
              sx={{
                width: 'inherit',
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px dashed #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                padding: '16px',
                marginBottom: '20px',
              }}
            >
              <Typography variant="body1" sx={{ textAlign: 'center', color: 'gray' }}>
                No data available for floc detector for this Date
              </Typography>
            </Box>
          )}

          {/* WILL DEICDE TO KEEP THIS OR NOT  */}
          {/* LOOKS FINE FOR NOW  */}
          {/* THIS IS THE BLOCK WHICH I NEED NOT TO BE TOUCHED STARTING */}
          {selectedTypeGraph === 'Sludge Settling Pattern' && flocData?.length > 0 && (
            // NEED TO CHANGE THE SHIFT LOGIC TO ONLY SAMPLE OVER HERE
            // WILL NEED TO PASS THE COLOR TOO OVER HERE FOR WIDGET THEME
            <Box
              sx={{
                width: 'inherit',
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                paddingBottom: '10px',
              }}
            >
              <LineChartFlocDetector
                flocData={flocData}
                selectedDate={currentDateIndex}
                selectedSampleIndex={currentSampleKey}
                imageCache={imageCache}
                handleClickOpen={handleClickOpen}
              />
            </Box>
          )}
          {selectedTypeGraph === 'Sludge Value' && (
            <StackedBarChartFlocDetector
              handleClickOpen={handleClickOpen}
              issueIcon={issueIcon}
              hiddenLabels={hiddenLabels}
              barGraphData={barGraphData}
              options={{
                plugins: {
                  zoom: {
                    pan: {
                      enabled: false, // Disable panning
                    },
                    zoom: {
                      wheel: {
                        enabled: false, // Disable zooming
                      },
                      pinch: {
                        enabled: false, // Disable pinch zooming
                      },
                    },
                  },
                },
              }}
            />
          )}
          {/* THIS IS THE BLOCK WHICH I NEED NOT TO BE TOUCHED ENDING EXCEPT SHIFT CONCEPT */}
        </Grid>
      </Grid>

      {/* THIS IS THE IMAGE DIALOG BOX IN WHICH WE NEED TO PASS DYNAMIC IMAGE DETAILS AND COMMENT START */}
      <ImageDialogueBox
        open={open}
        handleClose={handleClose}
        imageDetails={imageDetails}
        handleClick={handleClick}
        anchorEl={anchorEl}
        openComment={openComment}
        handleCloseComment={handleCloseComment}
        allComments={allComments}
      />
      <CommentDialogueBox
        openCommentDialogue={openCommentDialogue}
        handleCloseCommentDialogueOutside={handleCloseCommentDialogueOutside}
        commentDialogueHeaderText={commentDialogueHeaderText}
        handleCloseCommentDialogue={handleCloseCommentDialogue}
        handleOnChangeComment={handleOnChangeComment}
        addComment={addComment}
        errorTitle={errorTitle}
        setErrorTitle={setErrorTitle}
        errorComment={errorComment}
        setErrorComment={setErrorComment}
        handleAddComment={handleAddComment}
      />
      {/* THIS IS THE IMAGE DIALOG BOX IN WHICH WE NEED TO PASS DYNAMIC IMAGE DETAILS AND COMMENT START */}

      {/* THIS IS THE EXPANDED BLOCK OF FLOC DETECTOR VIZUALTION AND JUST 
      PERFECT TRY NOT TOUCHING IT BUT OF COURSE REMOVE THE SHIFT THING   START  */}
      <ExpandedViewFlocDetectorWidget
        openExpandedView={openExpandedView}
        handleCloseExpandedView={handleCloseExpandedView}
        widgetData={widgetData}
        resetZoom={resetZoom}
        setResetZoom={setResetZoom}
        showLinkIcon={showLinkIcon}
        linkOnWidget={linkOnWidget}
        selectedTypeGraph={selectedTypeGraph}
        imageCache={imageCache}
        flocData={flocData}
        selectedDate={currentDateIndex}
        selectedSampleIndex={currentSampleKey}
        barGraphData={barGraphData}
        handleClickOpen={handleClickOpen}
        issueIcon={issueIcon}
        hiddenLabels={hiddenLabels}
      />
      {/* THIS IS THE EXPANDED BLOCK OF FLOC DETECTOR VIZUALTION AND JUST 
      PERFECT TRY NOT TOUCHING IT BUT OF COURSE REMOVE THE SHIFT THING   START  */}
    </>
  );
};

// PUT THIS IS SOME OTHER COMPONENT
// TRY MAKING IT LESS MESSY
// JUST NEED TO EDIT THE LOGIC FOR LINECHART REMOVING THE SHIFT LOGIC , REST IS FINE START
const ExpandedViewFlocDetectorWidget = (props: any) => {
  const {
    selectedDate,
    selectedSampleIndex,
    handleClickOpen,
    openExpandedView,
    handleCloseExpandedView,
    widgetData,
    resetZoom,
    setResetZoom,
    showLinkIcon,
    linkOnWidget,
    barGraphData,
    selectedTypeGraph,
    imageCache,
    flocData,
    issueIcon,
    hiddenLabels,
  } = props;
  const clickedOnlinkIcon = () => {
    window.location.href = linkOnWidget;
  };
  return (
    <>
      <Dialog
        open={openExpandedView}
        onClose={handleCloseExpandedView}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '.MuiDialog-paper': {
            maxWidth: 'none',
            // padding: "0.5rem",
            borderRadius: '16px',
            height: selectedTypeGraph === 'Sludge Settling Pattern' ? '60vh' : 'auto',
            width: selectedTypeGraph === 'Sludge Settling Pattern' ? '180vh' : 'auto',
            overflow: 'hidden', // Prevent scrollbars
            display: 'flex', // Use flexbox to align content
            flexDirection: 'column', // Stack children vertically
          },

          '.MuiDialog-paper > *': {
            flex: '1 1 auto', // Allow children to grow and shrink to fit
            overflow: 'hidden', // Ensure child content does not overflow
            minHeight: '0', // Allow child elements to collapse correctly
          },
        }}
      >
        <Box className="widgetHeader" sx={{ ...styles.widgetHeader }}>
          <Stack direction="row" alignItems="center">
            <ToolTip title={widgetData?.widgetNickName || widgetData?.widgetName}>
              <Typography variant="h6" sx={styles.textStyle}>
                {widgetData?.widgetNickName || widgetData?.widgetName}
              </Typography>
            </ToolTip>
            {widgetData?.plantName && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginLeft: '8px',
                    marginRight: '8px',
                    borderRightWidth: 2,
                    color: '#7D8188',
                  }}
                />
                <Stack direction="row" alignItems="center" gap={1}>
                  <FactoryIcon
                    fontSize="small"
                    sx={{
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#80858CD9',
                      alignSelf: 'center',
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: '#7D8188', fontSize: '14px', fontWeight: 600 }}
                  >
                    {widgetData?.plantName}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            {
              <SecondaryButton
                sx={{ fontSize: '12px' }}
                onClick={() => {
                  // if (widgetData?.graphType) lineChartRef?.current?.resetZoom();
                  // else barChartRef?.current?.resetZoom();
                  setResetZoom(!resetZoom);
                }}
              >
                Reset Zoom
              </SecondaryButton>
            }
            {showLinkIcon && (
              <ToolTip title="Click to know more" style={{ padding: '0' }}>
                <img
                  src={linkIcon}
                  style={{ width: '14px', height: '14px' }}
                  onClick={() => {
                    clickedOnlinkIcon();
                  }}
                ></img>
              </ToolTip>
            )}
            <ToolTip title={widgetData?.widgetDescription}>
              <InfoOutlinedIcon fontSize="small" style={{ color: '#98A6B8' }} />
            </ToolTip>
            <ToolTip title="Close window">
              <CloseFullscreenIcon sx={{ fontSize: '16px' }} onClick={handleCloseExpandedView} />
            </ToolTip>
          </Stack>
        </Box>
        {selectedTypeGraph === 'Sludge Value' && (
          <StackedBarChartFlocDetector
            handleClickOpen={handleClickOpen}
            issueIcon={issueIcon}
            hiddenLabels={hiddenLabels}
            barGraphData={barGraphData}
            options={{
              responsive: true,
              plugins: {
                zoom: {
                  pan: {
                    enabled: true,
                    mode: 'xy',
                  },
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    mode: 'xy',
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  stacked: true,
                  min: 0, // Set minimum value for y-axis
                  max: 2000, // Set maximum value for y-axis
                  ticks: {
                    callback: function (value) {
                      return `${value} ml`;
                    },
                  },
                },
                x: {
                  stacked: true,
                  min: 'original', // Preserve original min value for x-axis
                  max: 'original', // Preserve original max value for x-axis
                  ticks: {
                    autoSkip: false,
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        )}
        {selectedTypeGraph === 'Sludge Settling Pattern' && (
          <LineChartFlocDetector
            flocData={flocData}
            selectedDate={selectedDate}
            selectedSampleIndex={selectedSampleIndex}
            imageCache={imageCache}
            handleClickOpen={handleClickOpen}
          />
        )}
      </Dialog>
    </>
  );
};
// JUST NEED TO EDIT THE LOGIC FOR LINECHART REMOVING THE SHIFT LOGIC , REST IS FINE END

export default FlocDetectorVisualizationChart;
